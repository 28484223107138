/* eslint-disable no-restricted-syntax */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner, Cta, Seo } from '../../components'
import imgBg from '../../assets/images/products/pacificcross_products_01.jpeg'
import {
  Benefits,
  BenefitsTable,
  Downloads,
  Header,
  Menu,
  Notes,
  Premium,
  Summary,
} from './components'

const ProductView = ({ loading, data }) => {
  const [menu, setMenu] = useState([])
  const { t } = useTranslation()

  const getMenu = () => {
    const menuKeys = [
      'summary',
      'benefits',
      'premium_overview',
      'downloads',
      'notes_exclusions',
    ]

    const newMenu = _.reduce(
      data,
      (result, value, key) => {
        if (_.includes(menuKeys, key) && !_.isEmpty(value)) {
          result.push({
            id: key,
            key,
          })
          return result
        }
        return result
      },
      [],
    )
    setMenu(newMenu)
  }

  useEffect(() => {
    getMenu()
  }, [data])

  useEffect(() => {
    getMenu()
  }, [])

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="container-fluid p-0">
      <Seo title={`${t('seo.title')}: ${_.get(data, 'name')}`} />
      <Header
        name={_.get(data, 'name')}
        brief={_.get(data, 'brief')}
        highlight={_.get(data, 'highlight')}
        buyNowLink={_.get(data, 'buy_now_link')}
        promotion={_.get(data, 'is_promotion')}
        category={_.get(data, 'category.parent.name')}
        subcategory={_.get(data, 'category.children[0].name')}
        imgBg={_.get(data, 'banner_image', imgBg)}
        t={t}
      />
      <Menu
        slug={_.get(data, 'slug')}
        buyNowLink={_.get(data, 'buy_now_link')}
        t={t}
        menu={menu}
      />
      <Summary
        id="summary"
        data={_.get(data, 'summary')}
        coverage={_.get(data, 'coverage')}
        t={t}
      />
      <Benefits id="benefits" data={_.get(data, 'benefits')} t={t} />
      <BenefitsTable
        data={_.get(data, 'benefit_highlights')}
        brochure={_.get(data, 'benefits_brochure')}
        t={t}
      />
      <Premium
        data={_.get(data, 'premium_overview')}
        id={'premium_overview'}
        brochure={_.get(data, 'premium_brochure')}
        t={t}
      />
      <Notes
        data={_.get(data, 'notes_exclusions')}
        id={'notes_exclusions'}
        t={t}
      />
      <Downloads data={_.get(data, 'downloads')} id={'downloads'} t={t} />
      <div className="container-xxl p-0">
        <Cta
          withAppend={true}
          info={{
            buttonText: t('product.header.buttonText'),
          }}
        />
      </div>
    </div>
  )
}

export default ProductView
